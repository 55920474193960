import { Link } from "react-scroll";

function Footer() {
    return (
        <>
            <footer className="mt-20 relative bottom-0">
                <section className="py-10 bg-[#261f25]/70">
                    <div className="max-w-screen-xl max-lg:px-10 mx-auto flex space-x-3 max-lg:flex-col max-xl:space-x-0 max-lg:space-y-3">
                        <div className="w-1/4 max-lg:w-full text-2xl text-white font-montserrat-extrabold">
                            <h2>CoWorking Space</h2>
                        </div>
                        <div className="w-1/4 max-lg:w-full text-white max-lg:pb-2 flex flex-col space-y-3 max-lg:border-r-0 max-lg:border-b-2 border-r-2 border-white">
                            <h2 className="text-lg font-montserrat-bold">Աշխատանքային Ժամեր</h2>
                            <p className="font-montserrat-semibold">08:00AM - 10:00PM</p>
                        </div>
                        <div className="w-1/4 max-lg:w-full text-white max-lg:pb-2 flex flex-col space-y-3 border-r-2 max-lg:border-r-0 max-lg:border-b-2 border-white">
                            <h2 className="text-lg font-montserrat-bold">ԳՐԱՍԵՆՅԱԿԻ ԱՇԽԱՏԱԿԻՑ</h2>
                            <p className="font-montserrat-semibold">523+</p>
                        </div>
                        <div className="w-1/4 max-lg:w-full text-white flex flex-col space-y-3">
                            <h2 className="text-lg font-montserrat-bold">ԱՇԽԱՏԱՆՔԱՅԻՆ ՏԱՐԱԾՔ</h2>
                            <p className="font-montserrat-semibold">3500m2</p>
                        </div>
                    </div>
                </section>
                <section className="bg-[#261f25]/80 py-10">
                    <div className="max-w-screen-xl mx-auto flex space-x-3 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-3 max-lg:px-10">
                        <div className="w-1/4 max-lg:w-full max-lg:border-r-0 max-lg:pb-2 max-lg:border-b-2 flex items-start justify-start flex-col space-y-3 text-white border-r-2 border-white">
                            <h2 className="font-montserrat-semibold text-lg">Կայքի Քարտեզ</h2>
                            <ul className="font-montserrat-light flex flex-col space-y-1">
                                <li>
                                    <Link activeClass='active' className='hover:text-mainColor transition-all duration-300 cursor-pointer' to={'weHave'} spy={true} smooth={true} duration={500}>Մեզ Մոտ</Link>
                                </li>
                                <li>
                                    <Link activeClass='active' className='hover:text-mainColor transition-all duration-300 cursor-pointer' to={'gallery'} spy={true} smooth={true} duration={500}>Պատկերներ</Link>
                                </li>
                                <li>
                                    <Link activeClass='active' className='hover:text-mainColor transition-all duration-300 cursor-pointer' to={'pricing'} spy={true} smooth={true} duration={500}>Փաթեթներ</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-2/4 max-lg:w-full max-lg:border-r-0 max-lg:pb-2 max-lg:border-b-2 flex flex-col space-y-3 text-white border-r-2 border-white">
                            <h2 className="font-montserrat-semibold text-lg">Մեր Մասին</h2>
                            <p className="font-montserrat-light">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque a fugit, explicabo perspiciatis
                                asperiores, quod recusandae tempora ipsam accusamus fugiat illo quas alias tempore molestias
                                ducimus aspernatur temporibus libero consequatur!
                            </p>
                        </div>
                        <div className="w-1/4 max-lg:w-full flex flex-col space-y-3 text-white">
                            <h2 className="font-montserrat-semibold text-lg">Սոց․Կայքեր</h2>
                            <ul className="social__icons">
                                <li>
                                    <a target="_blank" href>
                                        <i className="fa-brands fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href>
                                        <i className="fa-brands fa-instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href>
                                        <i className="fa-brands fa-linkedin" />
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href>
                                        <i className="fa-brands fa-tiktok" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="bg-[#261f25]/90 py-5">
                    <div className="text-white font-montserrat-bold max-w-screen-xl mx-auto max-lg:px-10">
                        <h3>
                            Copyright © 2023 CoWorking Space | Powered by Coworking Space
                        </h3>
                    </div>
                </section>
            </footer>
            <Link id="scroll" activeClass='ll' to={'mastHeader'} spy={true} smooth={true} duration={700} className="text-white text-2xl font-montserrat cursor-pointer">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-7 h-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                    </svg>
                </span>
            </Link>
        </>
    );
}

export default Footer