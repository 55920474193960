import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import IMG from '../../assets/images/ezgif-1-de2e8dcb46.webp'

function Gallery() {
    return (
        <>
            <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                className='bg-dark'
            >
                <a href={IMG} className="w-[48%] mb-12 max-lg:w-full max-lg:mb-10">
                    <img alt={"xdsd"} src={IMG} />
                </a>
                <a href={IMG} className="w-[48%] mb-12 max-lg:w-full max-lg:mb-10">
                    <img alt={"xdsd"} src={IMG} />
                </a>
                <a href={IMG} className="w-[30.5%] max-lg:w-full max-lg:mb-10">
                    <img alt={"xdsd"} src={IMG} />
                </a>
                <a href={IMG} className="w-[30.5%] max-lg:w-full max-lg:mb-10">
                    <img alt={"xdsd"} src={IMG} />
                </a>
                <a href={IMG} className="w-[30.5%] max-lg:w-full max-lg:mb-10">
                    <img alt={"xdsd"} src={IMG} />
                </a>
            </LightGallery>
        </>
    );
}

export default Gallery

