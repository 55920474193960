import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function Banner() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    return (
        <>
            <section className="h-[750px] max-lg:h-[500px] bg-mainColor">
                <div className='overflow-hidden'>
                    <div className="flex absolute justify-end w-full h-full items-center max-lg:h-[70%] pointer-events-none">
                        <div className="md:w-full p-10 lg:p-20 pt-20 rellax w-full" data-rellax-speed="1">
                            <div className="w-1/2 flex flex-col space-y-3 text-white max-lg:w-full">
                                <h2 className="z-50 text-3xl font-montserrat-bold max-lg:text-lg">
                                    Լավագույն համատեղ աշխատանքային տարածքները
                                    CoWorking Space-ում
                                </h2>
                                <p className="z-50 font-montserrat-extralight max-lg:text-sm">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate quos cumque repellat
                                    fugit sed dicta exercitationem totam! Id iusto quidem molestias, beatae, nam sint
                                    eligendi
                                    officiis aspernatur dicta quis molestiae!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        fullScreen: false,
                        background: {
                            color: {
                                value: "#4169e1",
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            detect_on: 'canvas',
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    particles_nb: 6,
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.4,
                                    speed: 3
                                },
                                remove: {
                                    particles_nb: 2,
                                },
                                bubble: {
                                    distance: 400,
                                    size: 40,
                                    duration: 2,
                                    opacity: 8,
                                    speed: 3
                                },
                                grab: {
                                    distance: 400,
                                    line_linked: {
                                        opacity: 1
                                    }
                                }
                            },
                        },
                        particles: {
                            move: {
                                enable: true,
                                speed: 1,
                                direction: 'bottom',
                                random: false,
                                straight: true,
                                out_mode: 'out',
                                bounce: false,
                                attract: {
                                    enable: true,
                                    rotateX: 600,
                                    rotateY: 1200,
                                }
                            },
                            number: {
                                value: 60,
                                density: {
                                    enable: true,
                                    value_area: 1000
                                }
                            },
                            color: {
                                value: ["#000", "#FFDD00", "#333", "#b7d3aa", "#808080"]
                            },
                            shape: {
                                type: 'polygon',
                                stroke: {
                                    with: 0,
                                    color: "#000"
                                },
                                polygon: {
                                    nb_sides: 5
                                }
                            },
                            opacity: {
                                value: .3,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 1,
                                    opacity_min: 0.1,
                                    sync: false
                                }
                            },
                            size: {
                                value: 25,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 30,
                                    size_min: 2,
                                    sync: false
                                }
                            },
                            line_linked: {
                                enable: true,
                                distance: 150,
                                color: "#ffffff",
                                opacity: 0.4,
                                with: 1
                            },
                        },
                        detectRetina: true,
                    }}
                />

            </section>
        </>


    );

}
export default Banner       
