import { Route, Routes } from 'react-router-dom';
import Home from './Pages/home';
import React from 'react'
import Header from './Pages/components/header';
import About from './Pages/about';
import Footer from './Pages/components/footer';

function App() {
  return(
    <>
    <Header />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About />} />
      </Routes>
      <Footer/>
      </>
  );
}

export default App;
