import React from 'react'

function About() {
    return (
        <>
            <div>
                <h1 className="text-3xl font-bold underline">About</h1>
            </div>
        </>
    );
}


export default About