import React from 'react'
import { Link } from 'react-scroll';

function Header() {
  const [open, setOpen] = React.useState(false);  
  return (
    <>
      <header className="fixed w-full z-[99]" id="mastHeader">
        <main className="before__header">
          <div className="flex justify-between max-w-screen-xl mx-auto">
            <div>
              <ul className="social__icons">
                <li>
                  <a target="_blank" href>
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href>
                    <i className="fa-brands fa-instagram" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href>
                    <i className="fa-brands fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href>
                    <i className="fa-brands fa-tiktok" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex justify-center items-center">
              <ul className="text-white flex space-x-5 text-sm font-montserrat">
                <li className="hover:text-blue-400 transition-all duration-300">
                  <a href="tel:+37493393345">Հեռ․։+374 93 39 33 45</a>
                </li>
                <li className="hover:text-blue-400 transition-all duration-300">
                  <a href="mailto:info@abovacademy.com">Էլ․Հասցե։info@abovacademy.com</a>
                </li>
              </ul>
            </div>
          </div>
        </main>
        <div className="py-5 z-[99] container-fluid fixed max-lg:p-5 max-lg:top-0 top-12 w-full flex justify-center transition-all duration-300 ease-linear bg-[#808080]/50" id='header__section'>
          <div className="container">
            <div className="flex justify-between items-center  py-2 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1 ">
                <Link activeClass='ll' to={'mastHeader'} spy={true} smooth={true} duration={500} className="text-white text-2xl font-montserrat cursor-pointer">CoWorking Space</Link>
              </div>
              <div className="md:hidden ">
                <button
                  type="button"
                  className="rounded-md   inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setOpen(!open)}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden md:flex space-x-10 relative z-[99]">
                <Link activeClass='active' className='header-link' to={'weHave'} spy={true} smooth={true} duration={500}>Մեզ Մոտ</Link>
                <Link activeClass='active' className='header-link' to={'gallery'} spy={true} smooth={true} duration={500}>Պատկերներ</Link>
                <Link activeClass='active' className='header-link' to={'pricing'} spy={true} smooth={true} duration={500}>Փաթեթներ</Link>
                <Link activeClass='active' className='header-link' to={'contactUs'} spy={true} smooth={true} duration={500}>Կապ Մեզ Հետ</Link>
              </nav>
            </div>
          </div>
          <div
            className={
              open
                ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2  transform origin-top-right md:hidden"
                : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            }
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 bg-[#808080] p-5 divide-gray-50">
              <div className="">
                <div className="flex items-center justify-between">
                  <div>
                    <Link activeClass='ll' to={'mastHeader'} spy={true} smooth={true} duration={500} className="text-white text-2xl font-montserrat cursor-pointer">CoWorking Space</Link>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="rounded-md p-2 inline-flex items-center justify-center text-white"
                      onClick={() => setOpen(!open)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                  <Link activeClass='active' className='header-link' to={'weHave'} spy={true} smooth={true} duration={500}>Մեզ Մոտ</Link>
                  <Link activeClass='active' className='header-link' to={'gallery'} spy={true} smooth={true} duration={500}>Պատկերներ</Link>
                  <Link activeClass='active' className='header-link' to={'pricing'} spy={true} smooth={true} duration={500}>Փաթեթներ</Link>
                  <Link activeClass='active' className='header-link' to={'contactUs'} spy={true} smooth={true} duration={500}>Կապ Մեզ Հետ</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header