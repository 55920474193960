import Gallery from "./gallery";
import IMG from '../../assets/images/ezgif-1-de2e8dcb46.webp'

function MainContent() {
    return (
        <>
            <div>
                <section id="weHave" className="bg-[#261f25] h-auto w-full py-20 bg-img">
                    <main className="max-w-screen-xl mx-auto font-montserrat uppercase">
                        <div className="flex flex-col space-y-5 mb-20">
                            <p className="text-center text-white font-montserrat-extralight">ՄԵզ Մոտ Կա </p>
                            <h2 className="text-center text-4xl text-white font-montserrat-bold max-lg:text-xl">ՄԵՆՔ ԿԱՌՈՒՑՈՒՄ ԵՆՔ ԱՊԱԳԱՅԻ
                                ՊԱՏՐԱՍՏ ԹՎԱՅԻՆ ՎԵԲ ՍՏՈՒԴԻԱ ԵՎ ՀԱՐԹԱԿՆԵՐ</h2>
                        </div>
                        <div className="text-gray-700 max-lg:px-10">
                            <div className="container mx-auto">
                                <div className="flex flex-wrap -m-4 text-center">
                                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full cursor-pointer">
                                        <div className="border-2 flex flex-col justify-center items-center space-y-5 border-[#808080] px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="mainColor" className="w-20 h-20 stroke-mainColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                                            </svg>
                                            <div>
                                                <p className="leading-relaxed text-white">ԱՆՎՃԱՐ WIFI</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full cursor-pointer">
                                        <div className="border-2 flex flex-col justify-center items-center space-y-5 border-[#808080] px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="mainColor" className="w-20 h-20 stroke-mainColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                            </svg>
                                            <div>
                                                <p className="leading-relaxed text-white">Conference Room</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full cursor-pointer">
                                        <div className="border-2 flex flex-col justify-center items-center space-y-5 border-[#808080] px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                                            <i className="fa-solid fa-mug-hot text-[80px] text-mainColor" />
                                            <div>
                                                <p className="leading-relaxed text-white">Սուրճ, թեյ</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full cursor-pointer">
                                        <div className="border-2 flex flex-col justify-center items-center space-y-5 border-[#808080] px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                                            <i className="fa-solid fa-brain text-[80px] text-mainColor" />
                                            <div>
                                                <p className="leading-relaxed text-white">Խմբային միջոցառումներ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </section>
                <section id="gallery" className="max-w-screen-xl mx-auto">
                    <Gallery/>
                </section>
                <section id="pricing" className="bg-[#261f25] bg-imgRight my-20 max-lg:my-10 bg-no-repeat bg-cover bg-left max-lg:bg-[url()]">
                    <main className="max-w-screen-xl mx-auto">
                        <div className="container mx-auto px-4 py-24">
                            <header className="text-center mb-16 text-white ">
                                <h1 className="text-5xl mb-4 font-montserrat-extrabold">Փաթեթներ</h1>
                            </header>
                            <div className="lg:flex lg:items-center lg:-mx-2 text-white">
                                <div className="mb-4 lg:mb-0 lg:w-1/3 lg:px-2">
                                    <div className="text-center border p-10 rounded">
                                        <h2 className="text-lg mb-4 font-montserrat-bold">Օրեկան Փաթեթ</h2>
                                        <div className="mb-6">
                                            <span className="block text-5xl pb-2 font-montserrat-light">3000֏</span>
                                            <span className="text-sm font-montserrat-thin">Օրեկան</span>
                                        </div>
                                        <ul className="leading-loose list-reset mb-6">
                                            <li><i className="fa-solid fa-wifi" /> WIFI</li>
                                            <li><i className="fa-solid fa-mug-hot" /> Սուրճ, Թեյ</li>
                                            <li><i className="fa-solid fa-kitchen-set" /> Խոհանոցից Օգտվել</li>
                                            <li><i className="fa-solid fa-percent" /> 20% զեղչ Conference Room - ի Համար</li>
                                        </ul>
                                        {/* <a className="text-sm tracking-wide uppercase inline-block border hover:text-black hover:bg-white transition-all duration-300 font-montserrat-semibold font-bold w-full p-3 rounded no-underline" href="#">Ամրագրել</a> */}
                                    </div>
                                </div>
                                <div className="mb-4 lg:mb-0 lg:w-1/3 lg:px-2">
                                    <div className="text-center border p-10 lg:py-16 rounded lg:shadow-lg">
                                        <h2 className="text-xl mb-4 font-montserrat-bold text-[#FFDD00]">VIP Փաթեթ</h2>
                                        <div className="mb-6">
                                            <span className="block text-5xl pb-2 font-montserrat-light">60,000֏</span>
                                            <span className="text-sm text-grey font-montserrat-thin">Ամսեկան</span>
                                        </div>
                                        <ul className="text-grey-dark leading-loose list-reset mb-6 font-montserrat-extralight">
                                            <li><i className="fa-solid fa-wifi" /> WIFI</li>
                                            <li><i className="fa-solid fa-mug-hot" /> Սուրճ, Թեյ</li>
                                            <li><i className="fa-solid fa-kitchen-set" /> Խոհանոցից Օգտվել</li>
                                            <li><i className="fa-solid fa-users" /> Conference Room: 8 Ժամ Անվճար</li>
                                        </ul>
                                        {/* <a className="hover:text-black hover:bg-white transition-all duration-300 text-sm tracking-wide uppercase inline-block border font-montserrat-semibold lg:hover:bg-red-dark lg:text-white font-bold w-full p-3 rounded no-underline" href="#">Ամրագրել</a> */}
                                    </div>
                                </div>
                                <div className="mb-4 lg:mb-0 lg:w-1/3 lg:px-2">
                                    <div className="text-center border border-grey-light p-10 rounded">
                                        <h2 className="text-lg mb-4 font-montserrat-bold">Ամսեկան Փաթեթ</h2>
                                        <div className="mb-6">
                                            <span className="block text-5xl pb-2 font-montserrat-light">30,000֏</span>
                                            <span className="text-sm text-grey font-montserrat-thin">Ամսեկան</span>
                                        </div>
                                        <ul className="leading-loose list-reset mb-6">
                                            <li><i className="fa-solid fa-wifi" /> WIFI</li>
                                            <li><i className="fa-solid fa-mug-hot" /> Սուրճ, Թեյ</li>
                                            <li><i className="fa-solid fa-kitchen-set" /> Խոհանոցից Օգտվել</li>
                                            <li><i className="fa-solid fa-percent" /> 20% զեղչ Conference Room - ի Համար</li>
                                        </ul>
                                        {/* <a className="hover:text-black hover:bg-white transition-all duration-300 text-sm tracking-wide uppercase inline-block border font-montserrat-semibold font-bold w-full p-3 rounded no-underline" href="#">Ամրագրել</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </section>
                <section className="max-w-screen-xl mx-auto mb-10">
                    <div className="flex max-lg:block max-lg:space-x-0 max-lg:px-10 space-x-20">
                        <div className="w-1/3 max-lg:w-full">
                            <ul className="font-montserrat">
                                <li className="flex justify-between py-10">
                                    <span>Ժամավճար</span>
                                    <span>։</span>
                                    <span>500֏</span>
                                </li>
                                <li className="h-[2px] bg-[#808080] w-full" />
                                <li className="flex justify-between py-10">
                                    <span>Conference Room</span>
                                    <span>։</span>
                                    <span>5000֏/Ժամ</span>
                                </li>
                            </ul>
                        </div>
                        <div className="w-2/3 h-[400px] max-lg:w-full">
                            <img className="w-full h-full object-cover" src={IMG} alt="" />
                        </div>
                    </div>
                </section>
                <section className="max-w-screen-xl mx-auto flex flex-col space-y-20 max-lg:px-10">
                    <div className="overflow-hidden rounded-3xl bg-transparent h-[500px] max-lg:h-[300px] w-full shadow-2xl shadow-gray-900">
                        <iframe className="w-full h-full invert-[1] grayscale-[100%]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d452.52759139302117!2d44.63422128492152!3d40.270626654836995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa135314364f3%3A0xe0e128cc39cfa145!2sAbovAcademy!5e0!3m2!1shy!2s!4v1676374556375!5m2!1shy!2s" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                    <div className="flex space-x-20 max-lg:block max-lg:space-x-0">
                        <div id="contactUs" className="w-1/3 max-lg:w-full">
                            <h1 className="font-montserrat-bold text-xl">ԹՈՂԵԼ ՄԵԿՆԱԲԱՆՈՒԹՅՈՒՆՆԵՐ</h1>
                            <ul className="font-montserrat">
                                <li className="flex justify-between py-10">
                                    <span>Հասցե</span>
                                    <span>։</span>
                                    <span>Ք․Աբովյան Սահմանադրության 8/1</span>
                                </li>
                                <li className="h-[2px] bg-[#808080] w-full" />
                                <li className="flex justify-between py-10">
                                    <span>Հեռախոսահամար</span>
                                    <span>։</span>
                                    <span><a href="tel:+37493393345">+374 93 39 33 45</a></span>
                                </li>
                                <li className="h-[2px] bg-[#808080] w-full" />
                                <li className="flex justify-between py-10">
                                    <span>Էլ․Հասցե։</span>
                                    <span>։</span>
                                    <span><a href="mailto:info@abovacademy.com">info@abovacademy.com</a></span>
                                </li>
                                <li className="h-[2px] bg-[#808080] w-full" />
                                <li className="flex justify-between py-10">
                                    <span>Սոց․Կայքեր։</span>
                                    <span>։</span>
                                    <ul className="social__icons text-black">
                                        <li>
                                            <a target="_blank" href>
                                                <i className="fa-brands fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href>
                                                <i className="fa-brands fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href>
                                                <i className="fa-brands fa-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href>
                                                <i className="fa-brands fa-tiktok" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="w-2/3 max-lg:w-full flex flex-col space-y-5">
                            <h1 className="font-montserrat-bold text-xl">ԹՈՂԵԼ ՄԵԿՆԱԲԱՆՈՒԹՅՈՒՆՆԵՐ</h1>
                            <form action className="font-montserrat flex flex-col space-y-5">
                                <div className="flex max-lg:flex-col max-lg:space-x-0 max-lg:space-y-5 space-x-5">
                                    <input className="w-1/2 max-lg:w-full p-5 border-2 border-[#808080] rounded-xl focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" type="text" placeholder="Անուն Ազգանուն" required />
                                    <input className="w-1/2 max-lg:w-full p-5 border-2 border-[#808080] rounded-xl focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" type="email1" placeholder="Էլ․Հասցե" required />
                                </div>
                                <textarea className="w-full p-5 border-2 border-[#808080] rounded-xl focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" name id rows={8} placeholder="Նամակ" required defaultValue={""} />
                                <input type="submit" defaultValue="Ուղարկել" className="cursor-pointer bg-mainColor w-1/4 py-3 rounded-xl text-white hover:bg-[#808080] transition-all duration-300" />
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default MainContent