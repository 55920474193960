import $ from 'jquery'

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });
});

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $('#mastHeader').removeClass("fixed");
            $('#header__section').removeClass("top-12");
            $('#header__section').removeClass("bg-[#808080]/50");
            $('#mastHeader').addClass("static");
            $('#header__section').addClass("top-0");
            $('#header__section').addClass("bg-[#808080]");
        } else if ($(this).scrollTop() < 0.1) {
            $('#mastHeader').addClass("fixed");
            $('#header__section').addClass("top-12");
            $('#header__section').removeClass("top-0");
            $('#header__section').addClass("bg-[#808080]/50");
            $('#header__section').removeClass("bg-[#808080]");
        }
    });
});