import React from 'react'
import Banner from './components/banner';
import MainContent from './components/mainContent';
import "../assets/js/script";

function Home() {
    return (
        <>
            <Banner/>
            <MainContent/>
        </>
    );
}


export default Home